import axios from "axios";

const BASE_URL = "https://dk-consult.ru:8080"

const http = axios.create({
    baseURL: BASE_URL
});



export default http;
