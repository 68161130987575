import axios from "axios";

const BASE_URL = "https://dk-consult.ru:8080"

const jwt = localStorage.getItem("jwt")

const mainForm = axios.create({
  baseURL: BASE_URL,

});

const mainFormService = {
  postForm: async (payload) => {
    const { data } = await mainForm.post("/api/customers/", payload);
    return data;
  },
};


export default mainFormService
