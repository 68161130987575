import { AboutCardWithText } from "./AboutCardWithText";
import cardimg1 from "../../assets/About/Фото.jpg";
import cardimg2 from "../../assets/About/Фото (1).jpg"
import cardimg3 from "../../assets/About/Фото (2).jpg"

export default function AboutCards() {

    const par1="В первую очередь, это относится к Cотрудникам компании, потому что без взаимного уважения невозможно создать обстановку доверия и атмосферу для раскрытия потенциала каждого участника. Уважение к Партнерам приводит к долгосрочным отношениям и развитию обеих компаний, а уважение к Клиенту воплощается в стремлении предоставлять безупречные товары и услуги."

    const par2="Любая идея беспомощна безналичия сил для ее реализации. Поэтому именно целеустремленность является базовым качеством успешного человека. Именно желание «достигать» формирует тот стержень, который дает силы для осуществления любых планов."

    const par3="Развитие для человека должно быть базовым жизненным сценарием - углубляться в рамках профессиональной деятельности, подниматься по ступеням осознанности. Для компании же развитие заключается в выполнении ее миссии - способствовать интеллектуальному и социально-эмоциональному развитию человеческого общества."

    return(
        <section className="max-w-[1440px] mx-auto py-[30px]">
            <AboutCardWithText 
                ltr={true}  
                heading="Уважение" 
                image={cardimg1}
                subheading={"Уважительное отношение к личности человека"}
                par={par1}
            />
            <AboutCardWithText 
                ltr={false}  
                heading="Достижение" 
                image={cardimg2}
                subheading={"Достижение поставленных целей"}
                par={par2}
            />
            <AboutCardWithText 
                ltr={true}  
                heading="Развитие" 
                image={cardimg3}
                subheading={"Развитие, как единственно возможный способ существования"}
                par={par3}
            />
        </section>
    )
}